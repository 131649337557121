<template>


  <transition name="slide">

  <div class="view-trip">
   <main-nav v-if="started == 0 || isOwner" />
    <main-titlebar myclass="purpleBar" :showback="showBack"/>
    <div class="trip-view-wrapper">
      <div v-for="trip in trips" :key="trip.id" class="trip-view-row2">
        <div class="grid-box-vscale" style="height:40vh;">

          <div v-if="trip.client > 0" class="trip-view-row">
            <div class="trip-view-col textAlignCenter"> <b> {{ trip.client_name }}</b></div>
          </div>

          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-circle"></i>  {{ statusFriendly(trip.status) }} ({{trip.status}})</div>
          </div>

          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-clock"></i>  {{ formatDateTime(trip.etd) }}</div>
          </div>
          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.origin_address}}</div>
          </div>
          <div class="trip-view-row">
            <div class="trip-view-col"><i class="fa-solid fa-crosshairs"></i> {{trip.destination_address}}</div>
          </div>



            <div v-if="started == 0">
                    
              <div v-if="trip.note" class="trip-view-row">
                <div class="trip-view-col"><i class="fa-solid fa-message"></i> <i>{{trip.note}}</i></div>
              </div>

              <div class="trip-view-row">
                <div class="trip-view-col2 textAlignLeft"><i class="fa-solid fa-car"></i> {{trip.provider_name}}</div>
                <div class="trip-view-col2 textAlignRight">  {{ formatCurrency(trip.price)}}</div>
              </div>

            </div>

            <div v-if="started == 1">
                <div class="trip-view-row">
                   <img id="anim-img" :src="taxiAnim" class="taxi-anim-frame" alt="Taxi drivin"/>
                </div>
            </div>

            </div>

            
            <div class="trip-view-row">
              <!-- showBack = {{showBack}} -->
              <!-- isStarted={{isStarted}} | started={{started}} | {{ te_ts }} -->
              <!-- curPosition = {{curPosition}} <br> curCenter = {{curCenter}} <br> markers = {{markers}} -->
              <!-- loggedIn={{loggedIn()}}- loggedinUser={{ loggedinUser }} - isOwner={{isOwner}} - isPublic={{isPublic}} - isDriver={{isDriver}} -->
            </div>

            <!-- only show when active -->
            <div v-if="status < 5">
              <div v-if="isOwner">
                <div v-if="status < 3">

                  <div v-if="status == 1">

                    <div class="trip-view-row">
                      <div class="trip-view-col">
                          <div v-if="started == 0" class="default-button" @click="editTrip(trip.id)"><i class="fa-solid fa-pen-to-square"></i> Aanpassen</div>
                      </div>
                    </div>

                    <div class="trip-view-row">
                      <div class="trip-view-col">
                          <div v-if="started == 0" class="default-button" @click="offerTrip(trip.id)"><i class="fa-solid fa-hand-holding-dollar"></i> Aanbieden</div>
                      </div>
                    </div>

                    
                  </div>
                  <div v-if="isDriver">

                    <div class="trip-view-row">
                      <div class="trip-view-col">
                          <div v-if="started == 0" class="default-button" @click="endTrip(trip.id)">Gereden</div>
                      </div>
                    </div>
                    <div class="trip-view-row">
                      <div class="trip-view-col">
                          <div v-if="started == 0" class="default-button" @click="cancelTrip(trip.id)">Annuleren</div>
                      </div>
                    </div>

                  </div>

                </div>
                <div v-if="isPublic == 1">
                  <div class="trip-view-row">
                    <div class="trip-view-col">
                        <div class="default-button" @click="revokeTrip(trip.id)">Terughalen uit pool </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="!isOwner">
                <div v-if="isPublic == 0">
                  <div v-if="isDriver">
                  <div class="trip-view-row">
                    <div class="trip-view-col">
                        <div v-if="started == 0">
                          <div class="default-button" @click="startTrip(trip.id)">Starten</div>
                        </div>
                        <div v-else>
                          <div class="default-button" @click="stopTrip(trip.id)">Stoppen</div>
                        </div>
                    </div>
                  </div>
                  <div class="trip-view-row">
                    <div class="trip-view-col">
                      <div v-if="te_ts != null" class="default-button" @click="endTrip(trip.id)">Eindigen</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <div v-if="isPublic == 1">
                  <div class="trip-view-row">
                    <div class="trip-view-col">
                        <div class="default-button" @click="acceptTrip(trip.id)">Rit aannemen</div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

            </div> 
            <!-- only show when active -->

            <div v-if="status >= 5">
              <div v-if="isOwner">
                <div class="trip-view-row">
                  <div class="trip-view-col">
                      <div class="default-button" @click="closeTrip(trip.id)">Rit archiveren</div>
                  </div>
                </div>
              </div>
            </div>

            <GMapMap
                :center="curCenter"
                :zoom="curZoom"
                map-type-id="terrain"
                style="width: 100%; height: 24vh;"
            >
            <GMapMarker
                  :key="marker.id"
                  v-for="marker in markers"
                  :position="marker.position"
            />
            </GMapMap>


          </div>
    </div>
  </div>

    </transition>

</template>
<script>
import { ref } from '@vue/reactivity';

import TripService from "../../services/trip-service";

export default {
  name:'settings',
  data() {
    var trips;
    var started;
    var te_ts;
    var tripMarkers = [];
    var curMarkers = [];
    var markers = [];
    var curZoom = 10;
    var openTrip = '';
    var tripowner;
    var showBack = true;
    var status;
    
    var taxiAnim = require('../../assets/taxi-drive-animation.gif');
    var defaultCenter = {lat: 51.093048, lng: 6.842120};
    var curCenter = defaultCenter;
    var curPosition = this.currentLocation();
    return { trips, status, markers,started,te_ts,taxiAnim,tripMarkers,curMarkers, curPosition, curCenter,curZoom,openTrip,showBack,tripowner,
    curGPSpos:this.currentLocation()
     }
  },
  methods: {
    offerTrip(id) {
      this.$router.push('/trips/offer/'+id);    
    },
    acceptTrip(id) {
      console.log("Accepting trip" + id);
      this.$router.push('/trips/accept/'+id);        
    },
    editTrip(id) {
      console.log("Editing trip" + id);
      this.$router.push('/trips/edit/'+id);        
    },
    centerOnLoc() {
      console.log("centerOnLoc:" + JSON.stringify(this.curMarkers[0]));
      this.curCenter =  ref(this.curMarkers[0]["position"]);    
      this.markers = this.curMarkers;
      this.curZoom = 16;   
    },
    centerOnTrip() {
      console.log("centerOnTrip:" + JSON.stringify(this.markers[0]));      
      this.curCenter = ref(this.tripMarkers[0]["position"]);
      this.markers = this.tripMarkers;    
      this.curZoom = 10;   
    },
    startTrip(id) {
      console.log("Starting trip" + id);  
      this.started = 1;
        this.openTrip = id;
      TripService.startTrip(id).then(
        (response) => {
          this.trips = response.data;     
          this.started = response.data[0]["started"];
          this.te_ts  = response.data[0]["te_ts"];
          this.showBack = false;
          this.centerOnLoc();       
 
        },
        (error) => {
          console.log("startTrip error: " + error.toString());
        }
      );     
    },
    stopTrip(id) {
      console.log("Stopping trip" + id);  
      localStorage.setItem('opentrip', '');
      this.started = 0;      
      this.openTrip = '';      
      this.showBack = true;
      TripService.stopTrip(id).then(
        (response) => {
            this.trips = response.data;     
            this.started = response.data[0]["started"];
            this.te_ts  = response.data[0]["te_ts"]; 
            this.centerOnTrip();                    
        },
        (error) => {
          console.log("stopTrip error: " + error.toString());
        }
      );     
    },
    endTrip(id) {
      console.log("Ending trip" + id);  
      this.started = 0;      
      localStorage.setItem('opentrip', '');      
      this.$router.push('/trips/end/'+id);  

    },   
    closeTrip(id) {
      console.log("Closing trip" + id);  
      this.started = 1;
      this.openTrip = id;
      TripService.closeTrip(id).then(
        (response) => {
          this.trips = response.data;     
          this.$router.push('/trips/my');      
 
        },
        (error) => {
          console.log("startTrip error: " + error.toString());
        }
      );     
    },
    cancelTrip(id) {
      console.log("Cancelling trip" + id);  
      this.started = 1;
        this.openTrip = id;
      TripService.cancelTrip(id).then(
        (response) => {
          this.trips = response.data;     
          this.$router.push('/trips/my');      
 
        },
        (error) => {
          console.log("startTrip error: " + error.toString());
        }
      );     
    },
    revokeTrip(id) {
      console.log("Revoking trip" + id);
      TripService.revokeTrip(id).then(
        (response) => {
          this.status = response.status;
          this.trips = response.data;
          console.log("Trip revoked from pool: " + JSON.stringify(this.trips));
          this.$router.push('/trips/open');        
        },
        (error) => {
          console.log("revokeTrip error: " + error.toString());
        }
      );
    }
  },
  mounted() {
    var targ = this;
    console.log("this.id: " + this.$route.params.id );
    TripService.getTrip(this.$route.params.id).then(
      (response) => {
        targ.status = response.status;

        targ.trips = response.data;
        targ.tripowner = targ.trips[0]["owner"];

        targ.status = targ.trips[0]["status"];
        targ.started = targ.trips[0]["started"];
        if(targ.started == 1) {
          targ.showBack = false;
        }
    
        
        targ.te_ts  = targ.trips[0]["te_ts"];
        console.log("data loaded from: " + JSON.stringify(targ.trips));
        var originCoords = targ.trips[0]["origin_location"].split(",");
        var origin_marker = {
          id: targ.trips[0]["origin_address"],
          position: {
            lat: parseFloat(originCoords[0]), lng: parseFloat(originCoords[1])
          }
          };

        console.log("origin marker: " + JSON.stringify(origin_marker));

        var destinationCoords = targ.trips[0]["destination_location"].split(",");

          var destination_marker = {
          id: 'destination',
          position: {
             lat: parseFloat(destinationCoords[0]), lng: parseFloat(destinationCoords[1])
          }
          };
          this.tripMarkers.push(origin_marker);
          this.tripMarkers.push(destination_marker);
          this.markers = this.tripMarkers;
          this.curMarkers = this.markers;

      },
      (error) => {
        targ.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );

    var par = this;
    this.$watchLocation()
    .then(coordinates => {
      console.log("Watch coordinates: " + JSON.stringify(coordinates));
          par.curGPSpos = coordinates
          localStorage.setItem('curloc', JSON.stringify(coordinates));
                    var loc_marker = {
        id: 'Mijn locatie',
        position: {
            lat: parseFloat(coordinates["lat"]), lng: parseFloat(coordinates["lng"])
        }
        };
        par.curMarkers = Array();
        par.curMarkers.push(loc_marker);
    });
      
  },
  computed: {

    isPublic() {
      return this.trips[0].public;
    },
    isStarted() {
      return ref(this.trips[0].started);
    },
    hasDriver() {
      return ref(this.trips[0].started);
    },
    isDriver() {
      var r = false;
      var tripDriver = this.trips[0]["driver"]
      var user = JSON.parse(localStorage.getItem('user')).userid;
      console.log("tripDriver: " + tripDriver + " | userid:" + user);
      if(user == tripDriver) {
        r = true;
      }
      return r;
    },

    curLatLon() {
      var coords = localStorage.getItem('curloc');
      var curGPSpos = JSON.parse(coords)
      var ll = curGPSpos.lat + "," + curGPSpos.lng; 
      return ll;
    },

    isOwner() {
      var r = false;
      var tripOwner = this.tripowner;
      var user = JSON.parse(localStorage.getItem('user')).userid;
      console.log("isOwner: " + tripOwner + " | userid:" + user);
      if(user == tripOwner) {
        r = true;
      }
      return r;
    },
  },
  
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>